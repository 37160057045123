import { initFilters } from 'helpers/filters'
import { action, computed, makeObservable, observable, when } from 'mobx'
import qs from 'qs'
import { ApiError, ItemFilter, ResourceState } from 'types'
import { DIContainer } from 'util/di'

import { Sort, Status } from 'constants/filters'
import { DistributionStatusLabel } from 'constants/resources'
import { captureError, getErrorMsg } from 'helpers/error'
import {
  AdminConnectedAccount,
  AdminPendingUser,
  GetApiV1ResourcesQueryParams,
} from 'types/api'
import Api from 'util/api'
import AuthStore from './AuthStore'
import { ResourcesFilter, SearchBy } from './FilterStore'

export default class PendingReviewStore {
  AuthStore: AuthStore
  api: Api
  state: ResourceState[]
  defaultState: ResourceState[] = [Status.Submitting, Status.PendingReview]

  defaultFilter: ItemFilter = {
    file_type: [],
    category_ids: [],
    content_type: 'all',
    program_type: 'all',
    license: 'all',
    sort: 'newest',
  }
  defaultSidebarFilter: ResourcesFilter = {
    category_id: [],
    content_type: [],
    license: [],
    program_type: [],
    file_type: [],
    sort: Sort.Newest,
    status: [Status.Submitting, Status.PendingReview],
    rating: [],
    releases: [],
    review_type: [],
    id_verification_status: [],
    file_history: [],
    uploaded: undefined,
    approved: undefined,
    reviewed: undefined,
    search_field: SearchBy.ContributorEmail,
    query: '',
    ai_generated: [],
    duplicate: [],
    linked_asset: [],
    auto_submitted: [],
    magic_metadata: [],
    auto_distribute: [],
    distribution_status: DistributionStatusLabel.Transferred,
    marketplace_ids: [],
  }

  constructor(container: DIContainer<Record<string, unknown>>) {
    makeObservable(this)
    this.AuthStore = container.find('AuthStore')
    this.api = container.find('api')
    this.state = this.defaultState

    when(
      () => Boolean(this.AuthStore.session),
      () => {
        if (this.AuthStore.session?.hasFeature('file_type_filter')) {
          delete this.defaultFilter.content_type
          delete this.filter.content_type
        }
      }
    )
  }

  @observable filter: ItemFilter = initFilters(this.defaultFilter)
  @observable loadingConnectedAccounts = false
  @observable loadingConnectedApplications = false
  @observable connectedAccounts: AdminConnectedAccount[] | undefined = undefined
  @observable connectedApplications: AdminPendingUser[] | undefined = undefined

  @action setFilter = (filter: ItemFilter, syncUrl = false): void => {
    this.filter = filter

    if (syncUrl) {
      const url = qs.stringify(this.filter, {
        encode: false,
        arrayFormat: 'comma',
        addQueryPrefix: true,
      })
      history.pushState({}, '', url)
    }
  }

  @action filterFromUrl(search: string, { isWO }: { isWO: boolean }): void {
    const filterParams = new URLSearchParams(search)

    if (Object.keys(filterParams).length > 0) {
      const newFilter = Object.keys(this.filter).reduce(
        (filter: ItemFilter, current: string): ItemFilter => {
          if (current !== 'nonce' && isWO && current !== 'program_type')
            filter[current] =
              filterParams.get(current) || this.defaultFilter[current]
          return filter
        },
        {} as ItemFilter
      )

      this.setFilter(newFilter)
    }
  }

  @action getPendingUserConnectedAccounts = async (
    pendingUserId: number
  ): Promise<void> => {
    this.loadingConnectedAccounts = true
    try {
      const res = await this.api.request(
        'GET',
        `/api/v1/admin/pending_users/${pendingUserId}/connected_accounts`
      )

      if (res) {
        this.connectedAccounts = res
      } else {
        this.connectedAccounts = undefined
      }
    } catch (e) {
      const errorMsg = getErrorMsg(e)

      if ((e as ApiError).status === 404 || errorMsg === 'Not Found') {
        return
      } else {
        captureError(
          e,
          'Error while fetching pending user connected accounts - stores/PendingReviewStore.ts'
        )
        throw e
      }
    } finally {
      this.loadingConnectedAccounts = false
    }
  }

  @action getPendingUserConnectedApplications = async (
    pendingUserId: number
  ): Promise<void> => {
    this.loadingConnectedApplications = true
    try {
      const res = await this.api.request(
        'GET',
        `/api/v1/admin/pending_users/${pendingUserId}/connected_applications`
      )

      if (res) {
        this.connectedApplications = res
      } else {
        this.connectedApplications = undefined
      }
    } catch (e) {
      const errorMsg = getErrorMsg(e)

      if ((e as ApiError).status === 404 || errorMsg === 'Not Found') {
        return
      } else {
        captureError(
          e,
          'Error while fetching pending user connected applications - stores/PendingReviewStore.ts'
        )
        throw e
      }
    } finally {
      this.loadingConnectedApplications = false
    }
  }

  @computed get queryParams(): GetApiV1ResourcesQueryParams {
    const params: GetApiV1ResourcesQueryParams = { state: this.state }

    if (this.filter.file_type?.length) {
      params.file_type = this.filter.file_type
    }

    if (this.filter.category_ids?.length) {
      params.category_ids = this.filter.category_ids
    }

    if (this.filter.content_type !== 'all' && this.filter.content_type) {
      params.content_type = [this.filter.content_type]
    }

    if (this.filter.program_type !== 'all' && this.filter.program_type) {
      params.program_type = this.filter
        .program_type as GetApiV1ResourcesQueryParams['program_type']
    }

    if (this.filter.license !== 'all' && this.filter.license) {
      params.license = this.filter
        .license as GetApiV1ResourcesQueryParams['license']
    }

    if (this.filter.sort === 'newest') {
      params.sort_field = 'created_at'
      params.sort_direction = 'desc'
    }

    if (this.filter.sort === 'oldest') {
      params.sort_field = 'created_at'
      params.sort_direction = 'asc'
    }

    if (this.filter.sort === 'downloads') {
      params.sort_field = 'lifetime_downloads'
      params.sort_direction = 'desc'
    }

    return params
  }
}
