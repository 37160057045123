import React, { useRef } from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  min?: number
  space?: number
  className?: string
}

const Grid = ({
  min = 350,
  space = 1,
  children,
  className,
}: Props): JSX.Element => {
  const gridRef = useRef<HTMLDivElement>(null)

  return (
    <S.GridContainer
      $min={min}
      $space={space}
      ref={gridRef}
      data-testid="eezy-layout-grid"
      className={className}
    >
      {children}
    </S.GridContainer>
  )
}

export default Grid
