import { captureError } from 'helpers/error'
import { action, makeObservable, observable, runInAction } from 'mobx'
import {
  GetApiV1FinanceNotableNotableIdNotesPathParams,
  GetApiV1NotesQueryParams,
  Note,
  PatchApiV1FinanceNotableNotableIdNotesId,
  PatchApiV1NotesId,
  PostApiV1FinanceNotableNotableIdNotes,
  PostApiV1Notes,
} from 'types/api'
import Api from 'util/api'
import { DIContainer, DIInstances } from 'util/di'

export default class NotesEndpointsStore {
  api: Api

  constructor(container: DIContainer<DIInstances>) {
    makeObservable(this)
    this.api = container.find('api')
  }

  @observable notes: Note[] = []

  @observable updatingNote = false
  @observable loadingNotes = false
  @observable creatingNote = false

  // Notes

  // Endpoint - useGetApiV1Notes
  @action fetchNotes = async (
    query: GetApiV1NotesQueryParams
  ): Promise<void> => {
    try {
      this.loadingNotes = true
      const notes = await this.api.request('GET', `/api/v1/notes/`, {
        query: {
          ...query,
        },
      })

      runInAction(() => {
        this.notes = notes
      })
    } catch (error) {
      captureError(
        error,
        'Error while fetching notes - stores/NotesEndpointsStore.ts'
      )
      throw error
    } finally {
      runInAction(() => {
        this.loadingNotes = false
      })
    }
  }

  // Endpoint - usePostApiV1Notes
  @action createNote = async (data: PostApiV1Notes): Promise<void> => {
    try {
      this.creatingNote = true
      const createdNote = await this.api.request('POST', `/api/v1/notes/`, {
        body: {
          ...data,
        },
      })

      runInAction(() => {
        this.notes.unshift(createdNote)
      })
    } catch (error) {
      captureError(
        error,
        'Error while creating a note - stores/NotesEndpointsStore.ts'
      )
      throw error
    } finally {
      runInAction(() => {
        this.creatingNote = false
      })
    }
  }

  // Endpoint - usePatchApiV1NotesId
  @action updateNote = async (
    noteId: number,
    data: PatchApiV1NotesId
  ): Promise<void> => {
    try {
      this.updatingNote = true
      const updatedNote = await this.api.request(
        'PATCH',
        `/api/v1/notes/${noteId}`,
        {
          body: {
            ...data,
          },
        }
      )

      runInAction(() => {
        if (data.active === false) {
          this.notes = this.notes.filter(
            note => String(note.id) !== String(noteId)
          )
        } else {
          const index = this.notes.findIndex(
            note => String(note.id) === String(noteId)
          )
          if (index !== -1) {
            this.notes[index] = updatedNote
          }
        }
      })
    } catch (error) {
      captureError(
        error,
        'Error while updating note - stores/NotesEndpointsStore.ts'
      )
      throw error
    } finally {
      runInAction(() => {
        this.updatingNote = false
      })
    }
  }

  // Earning Notes

  // Endpoint - useGetApiV1FinanceNotableNotableIdNotes
  @action fetchEarningNotes = async (
    notable: string,
    notable_id: string,
    query?: GetApiV1FinanceNotableNotableIdNotesPathParams
  ): Promise<void> => {
    try {
      this.loadingNotes = true
      const notes = await this.api.request(
        'GET',
        `/api/v1/finance/${notable}/${notable_id}/notes`,
        {
          query: {
            ...query,
          },
        }
      )

      runInAction(() => {
        this.notes = notes
      })
    } catch (error) {
      captureError(
        error,
        'Error while fetching earning notes - stores/NotesEndpointsStore.ts'
      )
      throw error
    } finally {
      runInAction(() => {
        this.loadingNotes = false
      })
    }
  }

  // Endpoint - usePostApiV1FinanceNotableNotableIdNotes
  @action createEarningNote = async (
    notable: string,
    notable_id: string,
    data:
      | { body: string; user_id: string }
      | PostApiV1FinanceNotableNotableIdNotes
  ): Promise<void> => {
    try {
      this.creatingNote = true
      const createdNote = await this.api.request(
        'POST',
        `/api/v1/finance/${notable}/${notable_id}/notes`,
        {
          body: {
            ...data,
          },
        }
      )

      runInAction(() => {
        this.notes.unshift(createdNote)
      })
    } catch (error) {
      captureError(
        error,
        'Error while creating an earning note - stores/NotesEndpointsStore.ts'
      )
      throw error
    } finally {
      runInAction(() => {
        this.creatingNote = false
      })
    }
  }

  // Endpoint - usePatchApiV1FinanceNotableNotableIdNotesId
  @action updateEarningNote = async (
    noteId: string,
    notable: string,
    notable_id: string,
    data:
      | { body: string; active: boolean }
      | PatchApiV1FinanceNotableNotableIdNotesId
  ): Promise<void> => {
    try {
      this.updatingNote = true
      const updatedNote = await this.api.request(
        'PATCH',
        `/api/v1/finance/${notable}/${notable_id}/notes/${noteId}`,
        {
          body: {
            ...data,
          },
        }
      )

      runInAction(() => {
        if (data.active === false) {
          this.notes = this.notes.filter(
            note => String(note.id) !== String(noteId)
          )
        } else {
          const index = this.notes.findIndex(
            note => String(note.id) === String(noteId)
          )
          if (index !== -1) {
            this.notes[index] = updatedNote
          }
        }
      })
    } catch (error) {
      captureError(
        error,
        'Error while updating note - stores/NotesEndpointsStore.ts'
      )
      throw error
    } finally {
      runInAction(() => {
        this.updatingNote = false
      })
    }
  }
}
