import { History, LinkedAsset, Releases, Sort, Status } from 'constants/filters'
import {
  BoolType,
  ContentType,
  DistributionStatusLabel,
  IdVerificationStatusFilter as IdVerificationStatus,
  LicenseType,
  ProgramType,
  ReviewType,
} from 'constants/resources'
import { format, parseISO } from 'date-fns'
import { DateRange } from 'helpers/dates'
import { isEqual } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { parse, stringify } from 'qs'

import { Rating, ResourceFileType } from 'types'
import { GetApiV1AdminResourcesSearchQueryParams } from 'types/api'
import { BulkOperation } from 'types/operations'

export enum SearchBy {
  ContributorEmail = 'user_email',
  ReviewerEmail = 'reviewer_email',
  Keyword = 'keywords',
  Assignment = 'assignment',
  ID = 'import_guid',
  Fingerprint = 'source_file_fingerprint',
}

export type ResourcesFilter = {
  [key: string]: unknown
  category_id: string[]
  content_type: ContentType[]
  license: LicenseType[]
  program_type: ProgramType[]
  file_type: ResourceFileType[]
  sort: Sort
  status: Status[]
  rating: (Rating | 'none')[]
  releases: Releases[]
  review_type: ReviewType[]
  id_verification_status: IdVerificationStatus[]
  file_history: History[]
  uploaded?: DateRange
  approved?: DateRange
  reviewed?: DateRange
  nonce?: string
  search_field: SearchBy
  query: string
  ai_generated: BoolType[]
  duplicate: BoolType[]
  linked_asset: LinkedAsset[]
  auto_submitted: BoolType[]
  magic_metadata: BoolType[]
  auto_distribute: BoolType[]
  distribution_status: string
  marketplace_ids: string[]
}

const initFilter = (filter: ResourcesFilter): ResourcesFilter => {
  const query = parse(window.location.search.replace('?', ''))

  return Object.keys(filter).reduce((acc, key) => {
    if (key === 'sort') {
      if ('sort_field' in query && 'sort_dir' in query) {
        if (query['sort_field'] === 'created_at') {
          if (query['sort_dir'] === 'desc') {
            acc[key] = Sort.Newest
          } else {
            acc[key] = Sort.Oldest
          }
        } else if (query['sort_field'] === 'title') {
          if (query['sort_dir'] === 'desc') {
            acc[key] = Sort.TitleDesc
          } else {
            acc[key] = Sort.TitleAsc
          }
        }
      } else {
        acc[key] = Sort.Newest
      }
    } else if (key === 'uploaded') {
      acc.uploaded = {}

      if ('created_at_start' in query && 'created_at_end' in query) {
        acc.uploaded.from = parseISO(query['created_at_start'] as string)
        acc.uploaded.to = parseISO(query['created_at_end'] as string)
      } else if ('created_at_start' in query) {
        acc.uploaded.from = parseISO(query['created_at_start'] as string)
        acc.uploaded.to = undefined
      } else if ('created_at_end' in query) {
        acc.uploaded.from = undefined
        acc.uploaded.to = parseISO(query['created_at_end'] as string)
      } else {
        acc.uploaded = filter[key]
      }
    } else if (key === 'reviewed') {
      acc.reviewed = {}

      if ('reviewed_at_start' in query && 'reviewed_at_end' in query) {
        acc.reviewed.from = parseISO(query['reviewed_at_start'] as string)
        acc.reviewed.to = parseISO(query['reviewed_at_end'] as string)
      } else if ('reviewed_at_start' in query) {
        acc.reviewed.from = parseISO(query['reviewed_at_start'] as string)
        acc.reviewed.to = undefined
      } else if ('reviewed_at_end' in query) {
        acc.reviewed.from = undefined
        acc.reviewed.to = parseISO(query['reviewed_at_end'] as string)
      } else {
        acc.reviewed = filter[key]
      }
    } else if (key === 'approved') {
      acc.approved = {}

      if ('approved_at_start' in query && 'approved_at_end' in query) {
        acc.approved.from = parseISO(query['approved_at_start'] as string)
        acc.approved.to = parseISO(query['approved_at_end'] as string)
      } else if ('approved_at_start' in query) {
        acc.approved.from = parseISO(query['approved_at_start'] as string)
        acc.approved.to = undefined
      } else if ('approved_at_end' in query) {
        acc.approved.from = undefined
        acc.approved.to = parseISO(query['approved_at_end'] as string)
      } else {
        acc.approved = filter[key]
      }
    } else if (key === 'releases') {
      if ('release_status' in query) {
        acc[key] = query['release_status']
          ? [Releases.With]
          : [Releases.Without]
      } else {
        acc[key] = filter[key]
      }
    } else if (key === 'file_history') {
      if ('file_history' in query) {
        acc[key] = query['file_history'] ? [History.With] : [History.Without]
      } else {
        acc[key] = filter[key]
      }
    } else if (key === 'status') {
      if ('state' in query) {
        const newStatus = Array.isArray(query['state'])
          ? query['state']
          : [query['state']]
        acc['status'] = newStatus.map(v => {
          switch (v) {
            case 'submitted':
              return Status.PendingReview
            case 'needs_corrections':
              return Status.ActionNeeded
            default:
              return v
          }
        }) as Status[]
      } else {
        acc['status'] = filter['status']
      }
    } else {
      acc[key] = query[key] || filter[key]
    }

    return acc
  }, {} as ResourcesFilter)
}

export default class AllAssetsStore {
  defaultFilter: ResourcesFilter = {
    category_id: [],
    content_type: [],
    license: [],
    program_type: [],
    file_type: [],
    sort: Sort.Newest,
    status: [
      Status.PendingReview,
      Status.Flagged,
      Status.ActionNeeded,
      Status.Approving,
      Status.Approved,
      Status.Published,
      Status.Rejected,
    ],
    rating: [],
    releases: [],
    review_type: [],
    id_verification_status: [],
    file_history: [],
    uploaded: undefined,
    approved: undefined,
    reviewed: undefined,
    search_field: SearchBy.ContributorEmail,
    query: '',
    ai_generated: [],
    duplicate: [],
    linked_asset: [],
    auto_submitted: [],
    magic_metadata: [],
    auto_distribute: [],
    distribution_status: DistributionStatusLabel.Transferred,
    marketplace_ids: [],
  }

  @observable filter: ResourcesFilter = initFilter(this.defaultFilter)
  @observable bulkOperations: BulkOperation[] = []

  constructor() {
    makeObservable(this)
  }

  @action setFilter = (filter: ResourcesFilter, syncUrl = false): void => {
    this.filter = filter

    if (syncUrl) {
      const url = `?${stringify(this.queryParams)}`
      history.pushState({}, '', url)
    }
  }

  @action resetFilters = (): void => {
    this.filter = { ...this.defaultFilter }
    const url = `?${stringify(this.queryParams)}`
    history.pushState({}, '', url)
  }

  @action addBulkOperation = (operation: BulkOperation) => {
    const operations = [...this.bulkOperations]

    operations.push(operation)

    this.bulkOperations = operations
  }

  @action removeBulkOperation = (operation: BulkOperation) => {
    const operations = [...this.bulkOperations]

    operations.splice(
      operations.findIndex((op: BulkOperation) => op.id === operation.id),
      1
    )

    this.bulkOperations = operations
  }

  @action updateBulkOperation = (operation: BulkOperation) => {
    const operations = [...this.bulkOperations]

    operations.splice(
      operations.findIndex((op: BulkOperation) => op.id === operation.id),
      1,
      operation
    )

    this.bulkOperations = operations
  }

  @computed get filterIsDirty(): boolean {
    return Object.entries(this.defaultFilter).some(
      ([key, value]) => !isEqual(this.filter[key], value)
    )
  }

  @computed get queryParams(): GetApiV1AdminResourcesSearchQueryParams {
    const params: GetApiV1AdminResourcesSearchQueryParams = {}

    const statusToStateMap: Record<string, string> = {
      [Status.Submitting]: 'submitting',
      [Status.PendingReview]: 'submitted',
      [Status.AcceptChanges]: 'needs_corrections',
      [Status.ActionNeeded]: 'needs_corrections',
      [Status.Flagged]: 'flagged',
      [Status.Approving]: 'approving',
      [Status.Approved]: 'approved',
      [Status.Exporting]: 'active',
      [Status.Published]: 'active',
      [Status.Rejected]: 'rejected',
      [Status.Started]: 'started',
      [Status.Inactive]: 'inactive',
    }

    if (this.filter.query && this.filter.search_field) {
      params.search_field = this.filter
        .search_field as GetApiV1AdminResourcesSearchQueryParams['search_field']
      params.query = this.filter.query
    }

    if (this.filter.status.length > 0) {
      params.state = this.filter.status.map(
        status => statusToStateMap[status]
      ) as GetApiV1AdminResourcesSearchQueryParams['state']
    }

    if (this.filter.category_id.length) {
      params.category_id = this.filter.category_id.map(x => Number(x))
    }

    if (this.filter.content_type.length > 0) {
      params.content_type = this.filter
        .content_type as GetApiV1AdminResourcesSearchQueryParams['content_type']
    }

    if (this.filter.program_type.length > 0) {
      params.program_type = this.filter
        .program_type as GetApiV1AdminResourcesSearchQueryParams['program_type']
    }

    if (this.filter.license.length > 0) {
      params.license = this.filter
        .license as GetApiV1AdminResourcesSearchQueryParams['license']
    }

    if (this.filter.file_type.length > 0) {
      params.file_type = this.filter
        .file_type as GetApiV1AdminResourcesSearchQueryParams['file_type']
    }

    if (this.filter.rating.length > 0) {
      params.rating = this.filter.rating.reduce(
        (acc, rating) => {
          if (rating === 'none') {
            return [...acc, 0]
          } else {
            return [...acc, rating]
          }
        },
        [] as (string | number)[]
      ) as GetApiV1AdminResourcesSearchQueryParams['rating']
    }

    if (this.filter.id_verification_status.length > 0) {
      params.user_id_verification_status = this.filter
        .id_verification_status as GetApiV1AdminResourcesSearchQueryParams['user_id_verification_status']
    }

    if (this.filter.review_type.length > 0) {
      params.review_type = this.filter
        .review_type as GetApiV1AdminResourcesSearchQueryParams['review_type']
    }

    if (this.filter.uploaded) {
      if (
        'from' in this.filter.uploaded &&
        this.filter.uploaded.from instanceof Date
      ) {
        params.created_at_start = format(
          this.filter.uploaded.from,
          'yyyy-MM-dd'
        )
      }

      if (
        'to' in this.filter.uploaded &&
        this.filter.uploaded.to instanceof Date
      ) {
        params.created_at_end = format(this.filter.uploaded.to, 'yyyy-MM-dd')
      }
    }

    if (this.filter.approved) {
      if (
        'from' in this.filter.approved &&
        this.filter.approved.from instanceof Date
      ) {
        params.approved_at_start = format(
          this.filter.approved.from,
          'yyyy-MM-dd'
        )
      }

      if (
        'to' in this.filter.approved &&
        this.filter.approved.to instanceof Date
      ) {
        params.approved_at_end = format(this.filter.approved.to, 'yyyy-MM-dd')
      }
    }

    if (this.filter.reviewed) {
      if (
        'from' in this.filter.reviewed &&
        this.filter.reviewed.from instanceof Date
      ) {
        params.reviewed_at_start = format(
          this.filter.reviewed.from,
          'yyyy-MM-dd'
        )
      }

      if (
        'to' in this.filter.reviewed &&
        this.filter.reviewed.to instanceof Date
      ) {
        params.reviewed_at_end = format(this.filter.reviewed.to, 'yyyy-MM-dd')
      }
    }

    if (this.filter.releases.length === 1) {
      const [release] = this.filter.releases
      params.release_status = release === Releases.With
    }

    if (this.filter.file_history.length === 1) {
      const [fileHistory] = this.filter.file_history
      params.file_history = fileHistory === History.With
    }

    if (this.filter.sort === Sort.Newest) {
      params.sort_field = 'created_at'
      params.sort_dir = 'desc'
    }

    if (this.filter.sort === Sort.Oldest) {
      params.sort_field = 'created_at'
      params.sort_dir = 'asc'
    }

    if (this.filter.sort === Sort.TitleAsc) {
      params.sort_field = 'title'
      params.sort_dir = 'asc'
    }

    if (this.filter.sort === Sort.TitleDesc) {
      params.sort_field = 'title'
      params.sort_dir = 'desc'
    }

    if (this.filter.ai_generated?.length) {
      const arr = this.filter.ai_generated

      if (arr.includes(BoolType.True) && arr.includes(BoolType.False)) {
        params.ai_generated = undefined
      } else if (arr.includes(BoolType.True)) {
        params.ai_generated = true
      } else {
        params.ai_generated = false
      }
    }

    if (this.filter.duplicate?.length) {
      const arr = this.filter.duplicate

      if (arr.includes(BoolType.True) && arr.includes(BoolType.False)) {
        params.duplicate = undefined
      } else if (arr.includes(BoolType.True)) {
        params.duplicate = true
      } else {
        params.duplicate = false
      }
    }

    if (this.filter.linked_asset.length === 1) {
      const [linkedAsset] = this.filter.linked_asset
      params.has_svg = linkedAsset === LinkedAsset.SVG
    }

    if (this.filter.auto_submitted?.length) {
      const arr = this.filter.auto_submitted

      if (arr.includes(BoolType.True) && arr.includes(BoolType.False)) {
        params.auto_submitted = undefined
      } else if (arr.includes(BoolType.True)) {
        params.auto_submitted = true
      } else {
        params.auto_submitted = false
      }
    }

    if (this.filter.magic_metadata?.length) {
      const arr = this.filter.magic_metadata

      if (arr.includes(BoolType.True) && arr.includes(BoolType.False)) {
        params.magic_metadata = undefined
      } else if (arr.includes(BoolType.True)) {
        params.magic_metadata = true
      } else {
        params.magic_metadata = false
      }
    }

    if (this.filter.auto_distribute.length) {
      const arr = this.filter.auto_distribute

      if (arr.includes(BoolType.True) && arr.includes(BoolType.False)) {
        params.auto_distribute = undefined
      } else if (arr.includes(BoolType.True)) {
        params.auto_distribute = true
      } else {
        params.auto_distribute = false
      }
    }

    if (this.filter.marketplace_ids.length && this.filter.distribution_status) {
      params.marketplace_statuses = []
      switch (this.filter.distribution_status) {
        case DistributionStatusLabel.Transferring:
          params.marketplace_statuses.push({
            marketplace_transferring: this.filter.marketplace_ids,
          })
          break
        case DistributionStatusLabel.Transferred:
          params.marketplace_statuses.push({
            marketplace_transferred: this.filter.marketplace_ids,
          })
          break
        case DistributionStatusLabel.TransferFailed:
          params.marketplace_statuses.push({
            marketplace_transfer_failed: this.filter.marketplace_ids,
          })
          break
        default:
          break
      }
    } else {
      params.marketplace_statuses = []
    }

    return params
  }
}
