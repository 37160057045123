import { ActionResponse } from 'constants/index'
import { ReviewStatus } from 'constants/resources'
import { MouseEvent, ReactElement } from 'react'
import { ResourceStore } from 'stores'
import { ItemFilter, ReviewItemFilter } from './filters'

import {
  GetApiV1ResourcesQueryParams,
  PatchApiV1ResourcesAcceptAllConditions,
  RejectionReason,
  Resource,
  ResponsesBulkUpdateSuccess,
} from 'types/api'

export type ResourceState = Resource['state']
export type ResourceContentType = Resource['content_type']
export type ResourceLicense = Resource['license']

export type ResourceClickArgs = {
  event: MouseEvent<HTMLElement>
  resource: Resource
  single?: boolean
}

export type Rating = 1 | 2 | 3 | 4 | 5
export type ReviewAction = Rating | 'flagged' | 'rejected'
export type ReviewFilter = ReviewAction | 'all'
export type ApprovedResource = {
  status: ReviewStatus.Approved
  resource: Resource
  rating: Rating
}

export type RejectedResource = {
  status: ReviewStatus.Rejected | ReviewStatus.Flagged
  resource: Resource
  rating?: Rating
  comment: string
  rejectionReason: RejectionReason
}

export type ReviewedResource = ApprovedResource | RejectedResource

export type ReviewedCounts = {
  rated: number[]
  flagged: number
  rejected: number
}

// using a generic type alias for these stores permits us to
// reuse the ResourceList component with other stores
export type ResourceListStore<T = ItemFilter> = {
  state: ResourceState[]
  reviewedResources: ReviewedResource[]
  selectedVisible: Resource[]
  resourcesAreReviewed?: boolean
  defaultFilter: T
  filter: T
  queryParams?: GetApiV1ResourcesQueryParams
  filterFromUrl?(search: string, { isWO }: { isWO: boolean }): void
  selectRatedResources(): void
  selectUnratedResources(): void
  setFilter(filter: T, syncUrl?: boolean): void
  setFilterNonce: () => void
  resetFilters(): void
  reviewFilters?: ReviewFilter[]
  reviewedCounts?: ReviewedCounts
  updateReviewedCounts?(): void
  getReviewStatus?: (num: number) => ReviewStatus
  getReviewedResources?: (arr: ReviewFilter[]) => Resource[]
  setReviewedResources: (reviewedResources: ReviewedResource[]) => void
  removeReviewStatus: (resources: Resource[]) => void
  setReviewStatus: ({
    resources,
    reviewStatus,
    rating,
  }: {
    resources: Resource[]
    reviewStatus: ReviewStatus
    rating?: Rating
    comment?: string
    rejectionReason?: RejectionReason
  }) => void
  submitReviewedResources: (
    contributorId: string,
    reviewedResources: ReviewedResource[]
  ) => Promise<{ succeededIds: string[]; failedIds: string[] }>
  totalPendingCount: number
  totalFilteredCount: number
  setTotalPendingCount: (total: number) => void
  setTotalFilteredCount: (total: number) => void
  setSourceFile?: (file: string) => void
  unassignResources: () => Promise<ActionResponse>
  acceptResources?: ({
    accept_as_editorial,
    accept_as_free,
  }: PatchApiV1ResourcesAcceptAllConditions) => Promise<
    ResponsesBulkUpdateSuccess & {
      succeededIds: string[]
    }
  >
  ResourceStore?: ResourceStore
}

export type ResourceReviewStore = ResourceListStore<ReviewItemFilter> & {
  successfullySubmittedIds: string[]
  clearSuccessfulSubmissions: () => void
}

export type CategoryUploadSetting = {
  id: string
  title: string
  icon: ReactElement
  isDirty?: boolean
  category_id?: string
  license?: 'free' | 'pro' | 'editorial' | 'none' | undefined
  ai_generated?: boolean
  auto_submit?: boolean
  magic_metadata?: boolean
  hasSetting?: boolean
  marketplace_account_ids?: string[]
  defaultZipCategory?: 'bundle' | 'motion_graphic' | string | undefined
}

export type ExtendedResource = Resource & {
  titleIsUnique?: boolean
  isSubmittable?: boolean
}

export enum QuickviewTab {
  View = 'view',
  TitleKeywords = 'title-keywords',
  FileFolders = 'file-folders',
}

export enum FileType {
  PNG = 'png',
  PSD = 'psd',
  EPS = 'eps',
  JPG = 'jpg',
  MP4 = 'mp4',
  MOV = 'mov',
  R3D = 'r3d',
  ZIP = 'zip',
}
