import { action, makeObservable, observable, runInAction } from 'mobx'
import Api from '../util/api'
import { DIContainer, DIInstances } from '../util/di'

import { Release, ResponsesLabelsReleaseMetadata } from 'types/api'

type Form = Release & {
  file?: File
}

export default class ReleaseStore {
  api: Api

  constructor(container: DIContainer<DIInstances>) {
    makeObservable(this)
    this.api = container.find('api')
  }

  @observable labels: Record<string, string[]> = {}
  @observable form: Form = {
    file: undefined,
    name: '',
    kind: 'model',
    hidden: 'false',
    age: '',
    gender: '',
    ethnicity: '',
  }

  @action updateForm = (field: keyof Form, value: string | File): void => {
    if (field === 'file') {
      this.form[field] = value as unknown as File
    } else {
      // @ts-ignore
      this.form[field] = value as unknown as string
    }
  }

  @action resetForm = (): void => {
    this.form = {
      file: undefined,
      name: '',
      kind: 'model',
      hidden: 'false',
      age: '',
      gender: '',
      ethnicity: '',
    }
  }

  @action fetchLabels = async (): Promise<
    ResponsesLabelsReleaseMetadata[] | Error
  > => {
    try {
      const res = await this.api.request(
        'GET',
        '/api/v1/labels/release_metadata'
      )

      if (res) {
        runInAction(() => {
          this.labels = res
        })
      }

      return res
    } catch (err) {
      throw new Error('Failed during release labels fetching')
    }
  }

  @action save = async (): Promise<Release> => {
    const formData = new FormData()
    formData.set('document', this.form.file || '')
    formData.set('kind', this.form.kind || '')
    formData.set('hidden', this.form.hidden || '')
    formData.set('name', this.form.name || '')

    if (this.form.kind === 'model') {
      formData.set('age', this.form.age || '')
      formData.set('gender', this.form.gender || '')
      formData.set('ethnicity', this.form.ethnicity || '')
    }

    try {
      const res = await this.api.request('POST', '/api/v1/releases', {
        body: formData,
      })

      return res
    } catch (err) {
      throw new Error('Failed during release attachment')
    }
  }

  @action patchName = async (
    id: string | undefined,
    name: string
  ): Promise<Release | null> => {
    const formData = new FormData()
    formData.set('name', name)

    if (!id) {
      return null
    }

    try {
      const res = await this.api.request('PATCH', `/api/v1/releases/${id}`, {
        body: formData,
      })

      return res
    } catch (err) {
      throw new Error('Failed during release patch')
    }
  }
}
