import cookie from 'js-cookie'
import { Context } from '.'
import Popup from './Popup'

import { User } from 'types/api'

export default class UserProfileModal extends Popup {
  constructor(context?: Context) {
    super(context)
    this.addCondition(this.checkVisibility)
    this.addCondition(this.checkCookie)
  }

  isAvailable = (user: User): boolean => {
    return !cookie.get(`user-profile-modal-${user.id}`)
  }

  checkCookie = (): boolean => {
    const { userId } = this.context
    return !cookie.get(`user-profile-modal-${userId}`)
  }

  checkVisibility = (): boolean => {
    const { session } = this.context

    return Boolean(!session?.isAdmin)
  }
}
