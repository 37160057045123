import { captureError } from 'helpers/error'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { PatchApiV1UserProfileNewPaymentAccount, UserProfile } from 'types/api'
import Api from 'util/api'
import { DIContainer, DIInstances } from 'util/di'

export default class ProfileEndpointsStore {
  api: Api

  constructor(container: DIContainer<DIInstances>) {
    makeObservable(this)
    this.api = container.find('api')
  }

  @observable userProfile: UserProfile | undefined = undefined

  @observable loadingUserProfile = false
  @observable creatingPaymentAccount = false

  // User Profile

  // Endpoint - useGetApiV1UserProfileId
  @action fetchUserProfile = async (sessionId: string): Promise<void> => {
    try {
      this.loadingUserProfile = true
      const userProfile = await this.api.request(
        'GET',
        `/api/v1/user_profile/${sessionId}`
      )

      runInAction(() => {
        this.userProfile = userProfile
      })
    } catch (error) {
      captureError(
        error,
        'Error while fetching user profile - stores/ProfileEndpointsStore.ts'
      )
      throw error
    } finally {
      runInAction(() => {
        this.loadingUserProfile = false
      })
    }
  }

  // Profile Payment Account

  // Endpoint - usePatchApiV1UserProfileNewPaymentAccount
  @action createPaymentAccount = async (
    data: PatchApiV1UserProfileNewPaymentAccount
  ): Promise<void> => {
    try {
      this.creatingPaymentAccount = true
      const updatedUser: UserProfile = await this.api.request(
        'PATCH',
        `/api/v1/user_profile/new_payment_account`,
        {
          body: {
            ...data,
          },
        }
      )

      runInAction(() => {
        this.userProfile = updatedUser
      })
    } catch (error) {
      captureError(
        error,
        'Error while creating a payment account - stores/ProfileEndpointsStore.ts'
      )
      throw error
    } finally {
      runInAction(() => {
        this.creatingPaymentAccount = false
      })
    }
  }
}
