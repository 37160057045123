import React, { useRef } from 'react'

import * as S from './styled'

type Props = {
  children: React.ReactNode
  space?: number
  noCenter?: boolean
  spaceBetween?: boolean
}

const Switcher = ({
  space = 1.5,
  noCenter,
  spaceBetween,
  children,
}: Props): JSX.Element => {
  const switcherRef = useRef<HTMLDivElement>(null)

  return (
    <S.Wrapper
      $space={space}
      $noCenter={!!noCenter}
      $spaceBetween={!!spaceBetween}
      ref={switcherRef}
      data-testid="eezy-layout-switcher"
    >
      {children}
    </S.Wrapper>
  )
}

export default Switcher
