import { observer } from 'mobx-react'
import { ReactNode, useEffect } from 'react'
import { Session } from 'types'
import MessageCollection from 'util/messages'
import { NavStore } from '../stores'
import { useInstance } from '../util/di'

import { useGetApiV1UiMessages, useGetApiV1UsersId } from 'api'
import { User } from 'types/api'

type Props = {
  session: Session
  children: ReactNode
}

function useUserResourceCounts(user: User | null, NavStore: NavStore) {
  useEffect(() => {
    if (user?.resource_counts) {
      NavStore.setContributorResourceCounts(user.resource_counts)
    }
  })
}

const UserResourceCountGetter = ({ session, children }: Props): JSX.Element => {
  const Messages = useInstance<MessageCollection>('messages')
  const NavStore = useInstance<NavStore>('NavStore')
  const { data: messages } = useGetApiV1UiMessages({})
  const { data: userData, refetch } = useGetApiV1UsersId({
    id: session.id,
    queryParams: { additional_fields: ['resource_counts'] },
  })

  useEffect(() => {
    refetch().catch(console.error)
  }, [session, refetch])

  useEffect(() => {
    NavStore.fetchCategories().catch(console.error)
  }, [NavStore])

  useEffect(() => {
    if (messages) {
      messages.forEach(msg => {
        if (msg.key && msg.copy) Messages.add(msg.key, msg.copy)
      })
    }
  }, [messages, Messages])

  useUserResourceCounts(userData, NavStore)

  return <>{children}</>
}

export default observer(UserResourceCountGetter)
