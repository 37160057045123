import { Routes } from 'constants/index'
import { LoadingSpinner } from 'eezy-components'
import cookie from 'js-cookie'
import FullWidthLayoutWrapper from 'layouts/full-width/FullWidthLayoutWrapper'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthStore } from 'stores'
import { useInstance } from 'util/di'

import { useGetApiV1Session } from 'api'

type Props = {
  apiToken: string
}

const SessionLoader: React.FC<Props> = ({ apiToken }) => {
  const navigate = useNavigate()
  const { setSession } = useInstance<AuthStore>('AuthStore')

  const { data: session, error } = useGetApiV1Session({
    requestOptions: { headers: { Authorization: 'Bearer ' + apiToken } },
  })

  useEffect(() => {
    if (session) {
      setSession(session)
    } else if (error) {
      cookie.remove(`eezy-cm-auth-token`)
      cookie.remove(`remembered`)
      navigate(Routes.SignIn)
    }
  }, [session, error, setSession, navigate])

  if (error) {
    navigate(Routes.SignIn)
    return null
  } else {
    return (
      <FullWidthLayoutWrapper>
        <LoadingSpinner />
      </FullWidthLayoutWrapper>
    )
  }
}

export default SessionLoader
