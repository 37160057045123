import { IdVerificationStatus } from 'constants/id-verification'
import { observable, runInAction } from 'mobx'

import ChallengeModal from './ChallengeModal'
import PersonaModal from './PersonaModal'
import PersonaToast from './PersonaToast'
import Popup from './Popup'
import ReferralsToast from './ReferralsToast'
import UserProfileModal from './UserProfileModal'

import { Challenge, Session } from 'types'
import { UsersResourceCounts } from 'types/api'

export { PersonaModal, PersonaToast, ReferralsToast, ChallengeModal }

export enum PopupTypes {
  PersonaModal = 'personaModal',
  PersonaToast = 'personaToast',
  ReferralsToast = 'referralsToast',
  ChallengeModal = 'challengeModal',
  UserProfileModal = 'userProfileModal',
}

export type Context = {
  [key: string]: unknown
  session?: Session
  status?: IdVerificationStatus
  resourceCounts?: UsersResourceCounts
  challenges?: Challenge[]
}

type Popups = {
  [key in PopupTypes]: Popup
}

type ValidPopups =
  | PersonaModal
  | PersonaToast
  | ReferralsToast
  | ChallengeModal
  | UserProfileModal

export default class PopupCollection {
  context: Context
  popups: Popups

  constructor(context?: Context) {
    this.context = observable(context || {})
    this.popups = {
      [PopupTypes.PersonaToast]: new PersonaToast(this.context),
      [PopupTypes.PersonaModal]: new PersonaModal(this.context),
      [PopupTypes.ReferralsToast]: new ReferralsToast(this.context),
      [PopupTypes.ChallengeModal]: new ChallengeModal(this.context),
      [PopupTypes.UserProfileModal]: new UserProfileModal(this.context),
    }
  }

  getPopup<T extends ValidPopups>(name: PopupTypes): T {
    return this.popups[name] as T
  }

  addContext(field: keyof Context, value: unknown): void {
    runInAction(() => (this.context[field] = value))
  }
}
