import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'

export const GridContainer = styled('div')<{
  $min?: number
  $space?: number
}>`
  display: grid;
  grid-gap: ${({ $space }) => themeSpacing($space ?? 1)};
  grid-template-columns: repeat(
    auto-fill,
    minmax(${({ $min }) => $min ?? 350}px, 1fr)
  );
`
