import { isEmpty } from 'helpers/objects'
import fetch from 'isomorphic-fetch'
import qs from 'query-string'
import Emitter, { Events } from './emitter'
import Service, { ServiceTypes } from './service'

import { ApiError } from 'types'
import { PatchApiV1Resources, ResponsesBulkUpdateSuccess } from 'types/api'

type Query = Record<string, unknown>
type Headers = Record<string, string>
type TextResponse = string | undefined

type Options = {
  body?: string | Record<string, unknown> | FormData
  query?: Query
  headers?: Headers
}

export type ResponseError = {
  error: string
  status?: number
  message?: string
  code?: string
}

export type NestedResponseError = {
  error: string
  status?: number
  message?: ResponseError
}

type Method =
  | 'GET'
  | 'PUT'
  | 'PATCH'
  | 'POST'
  | 'DELETE'
  | 'PATCH'
  | 'get'
  | 'put'
  | 'patch'
  | 'post'
  | 'delete'
  | 'patch'

export { Emitter, Events }
export { Service, ServiceTypes }

export default class Api {
  bypass: Service | null
  emitter: Emitter
  services: Service[]

  constructor() {
    this.bypass = null
    this.emitter = new Emitter()
    this.services = []
  }

  get service(): Service | null {
    if (this.bypass) {
      return this.bypass
    }

    if (!this.services.length) {
      console.error('[API] No services registered')
      return null
    }

    const result = this.services.find(s => s.isDefault)

    if (!result) {
      console.error('[API] No default service provided')
      return null
    }

    return result
  }

  use(key: $ValueOf<typeof ServiceTypes>): Api {
    const result = this.services.find(x => x.key === key)

    if (result) {
      this.bypass = result
    }

    return this
  }

  addService(service: Service): void {
    const idx = this.services.findIndex(x => x.key === service.key)

    if (idx > -1) {
      this.services[idx] = service
    } else {
      this.services.push(service)
    }
  }

  async updateResources(
    resources: PatchApiV1Resources['resources'],
    reviewAssignedToMe?: boolean
  ): Promise<ResponsesBulkUpdateSuccess> {
    const params: Record<string, unknown> = {
      resources,
    }

    if (reviewAssignedToMe) {
      params['review_assigned_to_me'] = reviewAssignedToMe
    }

    const res = await this.request('PATCH', '/api/v1/resources', {
      body: params,
    })
    return res as ResponsesBulkUpdateSuccess
  }

  // eslint-disable-next-line
  request(method: Method, path: string, options: Options = {}): Promise<any> {
    if (!this.service) {
      return Promise.reject()
    }

    const req = {
      url: this.service.host + path,
      method,
      headers: {} as Headers,
      body: options.body,
      query: {} as Query,
      credentials: 'include',
    }

    if (options.query) {
      Object.assign(
        req.query,
        Object.keys(options.query).reduce((obj, key) => {
          const value = options.query && options.query[key]

          // eslint-disable-next-line
          if (!!value) {
            obj[key] = value
          }

          return obj
        }, {} as Query)
      )
    }

    if (options.body) {
      if (method === 'GET') {
        Object.assign(req.query, options.body)
      } else if (options.body.toString() === '[object FormData]') {
        req.body = options.body
      } else {
        req.body = JSON.stringify(options.body)
      }
    }

    if (options.headers) {
      req.headers = { ...options.headers }
    }

    req.headers['Accept'] = req.headers['Accept'] || 'application/json'
    req.headers['Authorization'] = `Bearer ${this.service.token}`

    if (options.body && options.body.toString() !== '[object FormData]') {
      req.headers['Content-Type'] = 'application/json'
    }

    if (!isEmpty(req.query)) {
      req.url += `?${qs.stringify(req.query, { arrayFormat: 'bracket' })}`
    }

    if (this.bypass) {
      this.bypass = null
    }

    return new Promise((resolve, reject) =>
      fetch(req.url, req)
        .then((response: Response) => {
          if (response.status >= 200 && response.status < 300) {
            return response
              .text()
              .then((text: TextResponse) =>
                text ? resolve(JSON.parse(text)) : resolve({})
              )
          } else {
            /* i18n-tasks-use t('util.request_failed') */
            response
              .text()
              .then((text: TextResponse) => {
                let body = {}
                if (text) {
                  body = JSON.parse(text)
                }
                reject({
                  error: '[API] Request failed',
                  status: response.status,
                  message: body,
                })
              })
              .finally(() => {
                this.emitter.emit(Events.RequestError, {
                  msg: 'util.request_failed',
                  status: response.status,
                })
              })
          }
        })
        .catch((err: ApiError) => {
          console.error(err)

          if (err?.message === 'Network Request Failed') {
            this.emitter.emit(Events.NetworkError)
          }

          reject({ error: err })
        })
    )
  }
}
