import { TaskState } from 'constants/resources'
import { captureError, getErrorMsg } from 'helpers/error'
import { t } from 'i18next'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { FileImportFailure, FileUpload, FTPJob } from 'types'
import { KeywordAssignment, Resource } from 'types/api'
import { CategoryUploadSetting } from 'types/resources'
import Api, { ResponseError } from 'util/api'
import { DIContainer, DIInstances } from 'util/di'
import { GenericFile } from '../components/util/Droppable'
import AuthStore from './AuthStore'
import ResourceStore from './ResourceStore'

type FilestackImportsPayload = {
  uploads: { url: string; mimetype?: string }[]
  assignment_id?: number
}

type Batch = {
  id: string
  state: string
  failure_count: bigint
  import_failures: FileImportFailure[]
}

const mergeFiles = (
  files: FileUpload[],
  file: FileUpload,
  data: Partial<FileUpload> = {}
): FileUpload[] => {
  const idx = files.findIndex(f => f.name === file.name)

  if (idx === -1) {
    files.push({
      name: file.name,
      path: file.path,
      type: file.type,
      file: file.file,
      ...data,
    })
  } else {
    files[idx] = { ...files[idx], ...data }
  }

  return [...files]
}

export default class UploadStore {
  api: Api
  interval: number | undefined
  batches: string[]
  AuthStore: AuthStore
  ResourceStore: ResourceStore

  constructor(container: DIContainer<DIInstances>) {
    makeObservable(this)
    this.AuthStore = container.find('AuthStore')
    this.ResourceStore = container.find('ResourceStore')
    this.api = container.find('api')
    this.interval = undefined
    this.batches = []
  }

  @observable files: FileUpload[] = []
  @observable assignments: KeywordAssignment[] = []
  @observable selectedAssignment: KeywordAssignment | undefined = undefined
  @observable assignmentsLoaded = false
  @observable showAssignmentModal = false
  @observable uploadState: TaskState = TaskState.None
  @observable apiKey = ''
  @observable apiSecret = ''
  @observable categoryUploadSettings: CategoryUploadSetting[] = []
  @observable originalCategoryUploadSettings: CategoryUploadSetting[] = []
  @observable failedFTPJobs: FTPJob[] = []

  @action updateFiles = (
    file: FileUpload,
    data: Partial<FileUpload>,
    avoidUIUpdates = false
  ): void => {
    const filesArr = [...this.files]
    this.files = mergeFiles(filesArr, file, data)

    const errorFiles = filesArr?.filter(file => file.status === 'error')
    const completeFiles = filesArr?.filter(file => file.status === 'success')

    if (errorFiles.length > 0 && !avoidUIUpdates) {
      this.uploadState = TaskState.Error
    }

    if (completeFiles.length === filesArr.length && !avoidUIUpdates) {
      this.uploadState = TaskState.Success
    }
  }

  @action clearAssignmentModal = (): void => {
    this.showAssignmentModal = false
  }

  @action clearFiles = (): void => {
    this.files = []
  }

  @action createResource = async (
    file: FileUpload,
    fsResource: GenericFile
  ): Promise<void> => {
    const { session } = this.AuthStore

    const body: FilestackImportsPayload = {
      uploads: [fsResource],
    }

    if (session?.wo) {
      const assignmentId = this.selectedAssignment
        ? Number(this.selectedAssignment?.id)
        : undefined

      if (!assignmentId) {
        return
      }

      body.assignment_id = assignmentId
    }

    try {
      const batch = await this.api.request(
        'POST',
        '/api/v1/filestack_imports',
        { body }
      )

      if (batch?.id) {
        this.updateFiles(file, { batchId: batch.id, startTime: Date.now() })
        this.batches.push(batch.id)
        this.initPolling()
      }
    } catch (er) {
      const err = er as ResponseError
      console.error(err)
      this.updateFiles(file, { status: 'error', errors: [err.error] })
      this.uploadState = TaskState.Error

      if (err?.status == 422) {
        this.showAssignmentModal = true
      } else {
        throw new Error('Failed during resource creation')
      }
    }
  }

  @action updateResource = async (
    resource: Resource,
    file: FileUpload,
    fsResource: GenericFile,
    isPreviewVideo?: boolean,
    successCallback?: () => void
  ) => {
    const { hydrateResource } = this.ResourceStore

    if (isPreviewVideo) {
      try {
        const data = new FormData()
        data.set('resource[preview_video]', file.file as Blob, file.name)

        await this.api.request('PATCH', `/api/v1/resources/${resource.id}`, {
          body: data,
        })
        this.updateFiles(
          file,
          {
            status: 'success',
            progress: { totalPercent: 200 },
          },
          true
        )
        if (successCallback) {
          successCallback()
        }
      } catch (error) {
        const errors = file.errors || []

        errors.push(t(getErrorMsg(error, 'error') || 'error'))

        this.updateFiles(
          file,
          {
            status: 'error',
            errors: errors,
            progress: { totalPercent: 200 },
          },
          true
        )
        captureError(
          error,
          'Error while updating previeww video - stores/UploadStore.ts'
        )
      }
    } else {
      try {
        const batch = await this.api.request(
          'PATCH',
          `/api/v1/resources/${resource.id}/replace`,
          {
            body: { upload: fsResource },
          }
        )

        if (batch?.id) {
          this.updateFiles(file, { batchId: batch.id, startTime: Date.now() })
          this.batches.push(batch.id)
          this.initPolling({
            onComplete: ({ failure_count }: Batch) =>
              !failure_count &&
              hydrateResource(resource.id, ['source_file', 'preview_url']),
          })
        }

        return batch
      } catch (error) {
        const errors = file.errors || []
        errors.push(t(getErrorMsg(error, 'error') || 'error'))
        console.error(error)
        this.updateFiles(file, { status: 'error', errors })
        throw new Error('Failed during source file update')
      }
    }
  }

  @action selectAssignment = (id: string): void => {
    this.selectedAssignment = this.assignments.find(
      x => Number(x.id) === Number(id)
    )
  }

  @action clearAssignment = (): void => {
    this.selectedAssignment = undefined
  }

  @action setUploadState = (state: TaskState): void => {
    this.uploadState = state
  }

  @action setAPIKey = (key: string): void => {
    this.apiKey = key
  }

  @action setAPISecret = (secret: string): void => {
    this.apiSecret = secret
  }

  @action setCategoryUploadSettings = (
    settings: CategoryUploadSetting[],
    saveOriginal?: boolean
  ): void => {
    this.categoryUploadSettings = settings

    if (saveOriginal) {
      this.originalCategoryUploadSettings = settings
    }
  }

  initPolling = (
    options: { onComplete?: (batch: Batch) => void } = {}
  ): void => {
    if (!this.interval) {
      this.interval = window.setInterval(
        () => this.pollResources(options.onComplete),
        1000
      )
    }
  }

  pollResources = async (
    onComplete?: (batch: Batch) => void
  ): Promise<void> => {
    if (!this.batches?.length) {
      clearInterval(this.interval)
      this.interval = undefined
      return
    }

    const batches = await this.api.request('GET', '/api/v1/filestack_imports', {
      query: {
        ids: this.batches.join(','),
      },
    })

    if (batches?.length) {
      batches.forEach((batch: Batch) => {
        const idx = this.batches.findIndex(x => x === batch.id)
        const file = this.files.find(x => x.batchId === batch.id)

        if (batch.state === 'complete') {
          if (idx > -1) {
            this.batches.splice(idx, 1)
          }

          if (file) {
            this.updateFiles(file, {
              status: batch.failure_count ? 'error' : 'success',
              errors: batch.import_failures?.[0]?.error_keys,
              progress: { totalPercent: 200 },
            })

            onComplete && onComplete(batch)
          }
        } else if (file) {
          if ((file.startTime || 0) < Date.now() - 300000) {
            // i18n-tasks-use t('errors.importing.timeout')
            this.updateFiles(file, {
              status: 'error',
              errors: ['errors.importing.timeout'],
            })
          } else {
            const waiting = (Date.now() - (file.startTime || 0)) / 1000
            const progress = 200 - 300 / (waiting + 3)
            this.updateFiles(file, { progress: { totalPercent: progress } })
          }
        }
      })
    }
  }

  @action fetchAssignments = async (id?: string): Promise<void> => {
    if (!id) {
      return
    }

    try {
      const res = await this.api.request(
        'GET',
        `/api/v1/users/${id}/keyword_assignments`
      )

      runInAction(() => {
        // TODO: Remove unnecessary array check once api schema is fixed
        this.assignments = Array.isArray(res) ? res : []
        this.assignmentsLoaded = true
      })
    } catch (err) {
      console.error(err)
      this.assignmentsLoaded = true
    }
  }

  @action setFailedFTPJobs = (jobs: FTPJob[]): void => {
    this.failedFTPJobs = jobs
  }

  @action clearFailedFTPJobs = (): void => {
    this.failedFTPJobs = []
  }
}
