import { styled } from '@mui/system'
import { themeSpacing } from '../../../helpers'
import { Theme } from '@mui/material'

export const Wrapper = styled('div')<{
  theme: Theme
  $space?: number
  $noCenter: boolean
  $spaceBetween: boolean
}>`
  display: flex;
  align-items: ${({ $noCenter }) => ($noCenter ? 'normal' : 'center')};
  justify-content: ${({ $spaceBetween }) =>
    $spaceBetween ? 'space-between' : 'start'};
  gap: ${({ $space }) => themeSpacing($space)};

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (min-width: 769px) {
    flex-direction: row;
  }
`
