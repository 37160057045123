import { captureError } from 'helpers/error'
import { initFilters } from 'helpers/filters'
import { action, computed, makeObservable, observable, when } from 'mobx'
import qs from 'qs'
import { ItemFilter, ResourceState } from 'types'
import Api from 'util/api'
import { DIContainer } from '../util/di'

import AuthStore from './AuthStore'
import ResourceStore from './ResourceStore'

import { Sort, Status } from 'constants/filters'
import { DistributionStatusLabel } from 'constants/resources'
import {
  GetApiV1ResourcesQueryParams,
  ResponsesBulkUpdateSuccess,
} from 'types/api'
import { ResourcesFilter, SearchBy } from './FilterStore'

export default class NotApprovedStore {
  api: Api
  state: ResourceState[]
  AuthStore: AuthStore
  ResourceStore: ResourceStore
  defaultState: ResourceState[] = [
    Status.Rejected,
    Status.ActionNeeded,
    Status.Inactive,
  ]
  defaultFilter: ItemFilter = {
    file_type: [],
    category_ids: [],
    content_type: 'all',
    program_type: 'all',
    license: 'all',
    sort: 'newest',
    status: 'all',
  }
  defaultSidebarFilter: ResourcesFilter = {
    category_id: [],
    content_type: [],
    license: [],
    program_type: [],
    file_type: [],
    sort: Sort.Newest,
    status: [Status.Rejected, Status.ActionNeeded, Status.Inactive],
    rating: [],
    releases: [],
    review_type: [],
    id_verification_status: [],
    file_history: [],
    uploaded: undefined,
    approved: undefined,
    reviewed: undefined,
    search_field: SearchBy.ContributorEmail,
    query: '',
    ai_generated: [],
    duplicate: [],
    linked_asset: [],
    auto_submitted: [],
    magic_metadata: [],
    auto_distribute: [],
    distribution_status: DistributionStatusLabel.Transferred,
    marketplace_ids: [],
  }

  constructor(container: DIContainer<Record<string, unknown>>) {
    makeObservable(this)
    this.state = this.defaultState
    this.api = container.find('api')
    this.AuthStore = container.find('AuthStore')
    this.ResourceStore = container.find('ResourceStore')

    when(
      () => Boolean(this.AuthStore.session),
      () => {
        if (this.AuthStore.session?.hasFeature('file_type_filter')) {
          delete this.defaultFilter.content_type
          delete this.filter.content_type
        }
      }
    )
  }

  @observable filter: ItemFilter = initFilters(this.defaultFilter)
  @observable totalFilteredCount = 0

  @action setFilter = (filter: ItemFilter, syncUrl = false): void => {
    this.filter = filter

    if (syncUrl) {
      const url = qs.stringify(this.filter, {
        encode: false,
        arrayFormat: 'comma',
        addQueryPrefix: true,
      })
      history.pushState({}, '', url)
    }
  }

  @action filterFromUrl(search: string, { isWO }: { isWO: boolean }): void {
    const filterParams = new URLSearchParams(search)

    if (Object.keys(filterParams).length > 0) {
      const newFilter = Object.keys(this.filter).reduce(
        (filter: ItemFilter, current: string): ItemFilter => {
          if (current !== 'nonce' && isWO && current !== 'program_type')
            filter[current] =
              filterParams.get(current) || this.defaultFilter[current]
          return filter
        },
        {} as ItemFilter
      )

      this.setFilter(newFilter)
    }
  }

  @computed get queryParams(): GetApiV1ResourcesQueryParams {
    const params: GetApiV1ResourcesQueryParams = { state: this.state }

    if (this.filter.file_type?.length) {
      params.file_type = this.filter.file_type
    }

    if (this.filter.category_ids?.length) {
      params.category_ids = this.filter.category_ids
    }

    if (this.filter.content_type !== 'all' && this.filter.content_type) {
      params.content_type = [this.filter.content_type]
    }

    if (this.filter.program_type !== 'all' && this.filter.program_type) {
      params.program_type = this.filter
        .program_type as GetApiV1ResourcesQueryParams['program_type']
    }

    if (this.filter.license !== 'all' && this.filter.license) {
      params.license = this.filter
        .license as GetApiV1ResourcesQueryParams['license']
    }

    if (this.filter.sort === 'newest') {
      params.sort_field = 'created_at'
      params.sort_direction = 'desc'
    }

    if (this.filter.sort === 'oldest') {
      params.sort_field = 'created_at'
      params.sort_direction = 'asc'
    }

    if (this.filter.status === 'all') {
      params.state = this.defaultState
    } else if (
      this.filter.status === 'accept_changes' ||
      this.filter.status === 'needs_corrections'
    ) {
      params.state = ['needs_corrections']
      params.accept_changes = this.filter.status !== 'needs_corrections'
    } else if (this.filter.status === 'inactive') {
      params.state = ['inactive']
    } else {
      params.state = [this.filter.status] as unknown as typeof params.state
    }

    return params
  }

  @action setTotalFilteredCount = (total: number): void => {
    this.totalFilteredCount = total
  }

  @action acceptResources = async ({
    accept_as_free,
    accept_as_editorial,
  }: {
    accept_as_free: boolean
    accept_as_editorial: boolean
  }): Promise<ResponsesBulkUpdateSuccess & { succeededIds: string[] }> => {
    try {
      const { failed_ids, ...rest } = await this.api.request(
        'PATCH',
        '/api/v1/resources/accept_all_conditions',
        {
          body: { accept_as_free, accept_as_editorial },
        }
      )

      const succeededIds = this.ResourceStore.visibleResources
        .filter(
          resource =>
            !(
              (failed_ids &&
                failed_ids.length > 0 &&
                failed_ids.includes(String(resource.id))) ||
              !(
                (accept_as_free && resource.acceptable_as_free) ||
                (accept_as_editorial && resource.acceptable_as_editorial)
              )
            )
        )
        .map(({ id }) => id)

      this.ResourceStore.setRefreshedResources(succeededIds)
      this.ResourceStore.setLockedReason(succeededIds, 'bulk_update')
      return { ...rest, failed_ids, succeededIds }
    } catch (e) {
      captureError(
        e,
        'Error while accepting resources - stores/NotApprovedStore.ts'
      )
      throw e
    }
  }
}
